import { useAuth0 } from '@auth0/auth0-react';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import nl from 'date-fns/locale/nl';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ReactGA from 'react-ga';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningModal from 'src/components/modals/WarningModal';
import Service from 'src/services/api/Api';
import { AppPageRoutes } from 'src/services/api/models';
import RoleBaseAccess from 'src/services/RoleBaseAccess';
import RootStore from 'src/stores/RootStore';
import UserStore from 'src/stores/UserStore';
import { UserDataAuth, UserDataAuthParsed } from 'src/types/commons/users';
import CONFIG from 'src/utils/config';
import {
    RoleForbiddenAccessDefinitions,
    StageEnviroments,
    UserAuth0Namespace,
    UserRoles,
    ViewModeEnum,
} from 'src/utils/constants';
import { IntegrationServices } from './IntegrationServices';
import LoginPage from './modules/authentication/Login';
import { ProtectedRoute } from './modules/authentication/ProtectedRoute';
import Unauthorized from './modules/authentication/Unauthorized';
import BikeDashboard from './modules/bikeDashboard/bikeDashboard';
import BikeDetail from './modules/bikeDashboard/BikeDetails';
import Contact from './modules/contact/Contact';
import Dashboard from './modules/dashboard/Dashboard';
import GPSPage from './modules/gps/GPS';
import ReturnsProcessInfo from './modules/interstitial/ReturnsProcessInfo';
import PaymentProcessingPage from './modules/interstitial/PaymentProcessing';
import ThankYouPage from './modules/interstitial/ThankYouPage';
import ThankYouReturnsPage from './modules/interstitial/ThankYouReturnsPage';
import InvoiceDetails from './modules/invoices/InvoiceDetails';
import Invoices from './modules/invoices/Invoices';
import KnowledgeInfo from './modules/knowledgeInfo/KnowledgeInfo';
import LayoutPage from './modules/Layout';
import InitialLoading from './modules/loading/InitialLoading';
import MarketingMaterialsPage from './modules/marketingMaterials/MarketingMaterialsPage';
import NewReturn from './modules/newReturn/NewReturn';
import News from './modules/news/News';
import OrderNotifications from './modules/notifications/OrderNotifications';
import OrderDetailPage from './modules/orders/OrderDetailPage';
import Orders from './modules/orders/Orders';
import ProductsPage from './modules/products/ProductsPage';
import ReturnRequestDetails from './modules/returns/ReturnRequestDetails';
import ReturnRequestSelectItemPage from './modules/returns/fileUpload/ReturnRequestSelectItemPage';
import ReturnRequests from './modules/returns/ReturnRequests';
import SparePartsPage from './modules/spareParts/SparePartsPage';
import ShipReturns from './modules/shipReturns/ShipReturns';
import withRoot from './modules/withRoot';
import NotFound from './NotFound';

declare global {
    interface Window {
        userGuiding: any;
    }
}

interface AppProps {
    user?: typeof UserStore.Type;
    store?: typeof RootStore.Type;
}

const cache = createIntlCache();

// TODO: replace react-datepicker with @mui/x-date-pickers
// Register date picker languages
registerLocale('nl', nl);
setDefaultLocale('nl');

// Map for @mui/x-date-pickers languages
const LangLocaleMap: Record<string, Locale> = {
    'nl-NL': nl,
    'en-GB': enGB,
    de: de,
};

const App = inject(
    'store',
    'user',
)(
    observer(({ store, user }: AppProps) => {
        const location = useLocation();
        const { isAuthenticated, user: userAuth0, getAccessTokenSilently } = useAuth0<UserDataAuth>();

        const [messages, setMessages] = useState<Record<string, any>>({});
        const [loading, setLoading] = useState(true);
        const [isTokenLoaded, setIsTokenLoaded] = useState(false);

        const interval = useRef<ReturnType<typeof setInterval>>();

        const lang = user!.preferredLang;

        // Setting default locale of 'dayjs' globally
        useEffect(() => {
            dayjs.locale(lang ? lang.substr(0, 2) : 'en');
        }, [lang]);

        if (CONFIG.APP_ENVIRONMENT === StageEnviroments.PRODUCTION) {
            ReactGA.initialize(CONFIG.GOOGLE_ANALYTICS_ID);

            // Plugin for sending Enhanced Ecommerce metrics into Google Analytics
            ReactGA.plugin.require('ec');

            useEffect(() => {
                ReactGA.set({ page: location.pathname }); // Update the user's current page
                ReactGA.pageview(location.pathname); // Recor
            }, [location]);
        }

        // Retrieving order notifications periodically
        useEffect(() => {
            setLoading(true);
            let internalInterval: ReturnType<typeof setInterval> | undefined;

            if (store && user) {
                if (interval.current) {
                    clearInterval(interval.current);
                }

                if (CONFIG.ORDER_NOTIFICATION_REFRESH_INTERVAL > 0) {
                    // This effect cannot handle user property changes, so we are checking the value change periodically here
                    internalInterval = setInterval(() => {
                        if (user.isAuthenticated) {
                            clearInterval(internalInterval!);
                            internalInterval = undefined;

                            interval.current = setInterval(() => {
                                if (user && user.isAuthenticated) {
                                    store.orderNotifications.requestNewNotifications();
                                }
                            }, CONFIG.ORDER_NOTIFICATION_REFRESH_INTERVAL);

                            store.orderNotifications.requestNewNotifications();
                        }
                    }, 500);
                }
                // Initial call
                if (user.isAuthenticated) {
                    store.orderNotifications.requestNewNotifications();
                }
            }

            // Clearing intervals at unmount
            return () => {
                if (internalInterval) {
                    clearInterval(internalInterval);
                }

                if (interval.current) {
                    clearInterval(interval.current);
                }
            };
        }, [store, user]);

        useEffect(() => {
            const loadTranslations = async () => {
                try {
                    const res = await Service.getGridlytranslations(lang);
                    setMessages(res);
                    setLoading(false);
                } catch (errorGridly) {
                    console.error(errorGridly);
                    // Get translation from excels sheet is Gridly api fail
                    try {
                        const res = await Service.getTranslations();
                        if (res?.data) {
                            setMessages(res?.data);
                            setLoading(false);
                        }
                    } catch (errorDP) {
                        setLoading(false);
                        console.error(errorDP);
                    }
                }
            };

            loadTranslations();
        }, [lang]);

        useEffect(() => {
            if (user?.role !== UserRoles.MECHANIC && user?.showConsumerPrices) {
                store!.config.setViewMode(ViewModeEnum.CONSUMER);
            }
        }, [user?.showConsumerPrices]);

        useEffect(() => {
            const setToken = async () => {
                try {
                    const token = await getAccessTokenSilently();

                    Service.setHeaders({ token });
                    user!.setToken(token);
                    setIsTokenLoaded(true);
                    user?.getUserPreferences();
                } catch (e) {
                    console.error(e);
                    setTimeout(() => window.location.replace(AppPageRoutes.UNAUTHORIZED), 500);
                }
            };

            if (user && isAuthenticated && userAuth0) {
                setToken();

                const metaUser: UserDataAuthParsed = JSON.parse(userAuth0[UserAuth0Namespace]);

                if (metaUser?.salesforce) {
                    const { salesforce: sfUser } = metaUser;

                    const role =
                        sfUser?.contact?.role && Object.values(UserRoles).includes(sfUser.contact.role)
                            ? sfUser.contact.role
                            : null;

                    const userModel = {
                        userId: userAuth0.sub || '',
                        accountId: sfUser.account?.id || '',
                        accountNumber: sfUser.account?.erpId || '',
                        name: userAuth0.name || '',
                        role,
                        paymentsEnabled: sfUser.contact?.paymentsEnabled || false,
                    };

                    user.setUser(userModel);

                    // Check User Role Page Access
                    if (role) {
                        RoleBaseAccess.setUserRole(role);
                    }

                    RoleBaseAccess.setDefinitions(RoleForbiddenAccessDefinitions);

                    // Force the view mode to consumer mode if the user is a mechanic
                    if (role === UserRoles.MECHANIC) {
                        store!.config.setViewMode(ViewModeEnum.CONSUMER);
                    }

                    store!.setBillingCountry(sfUser.billingAddress?.countryCode || '');
                }

                // Feature flags from auth0
                if (metaUser?.features?.newReturnRequests) {
                    user.changeUseNewReturnRequest(true);
                }

                // GA set user
                ReactGA.set({
                    userId: user.userId,
                    username: user.name,
                    accountNumber: user.accountNumber,
                });

                // Sentry set user
                if (
                    CONFIG.APP_ENVIRONMENT !== StageEnviroments.LOCAL &&
                    CONFIG.SENTRY_RELEASE !== '' &&
                    CONFIG.ADD_SENTRY_RELEASE
                ) {
                    // User data log and extra parameters
                    Sentry.configureScope(scope => {
                        scope.setUser({
                            id: user.userId,
                            username: user.name,
                        });

                        scope.setExtra('accountId', user.accountId);
                        scope.setExtra('accountNumber', user.accountNumber);
                    });
                }
            }
        }, [store, user, getAccessTokenSilently, isAuthenticated, userAuth0]);

        const intl = createIntl(
            {
                locale: lang,
                messages: messages[lang],
            },
            cache,
        );

        // Sentry React Router Integration
        const SentryRoutes = SentryReact.withSentryReactRouterV6Routing(Routes);

        return (
            <>
                <IntegrationServices />
                {lang && messages[lang] ? (
                    <RawIntlProvider value={intl}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LangLocaleMap[lang] ?? nl}>
                            <SentryRoutes>
                                <Route path={AppPageRoutes.ROOT} element={<LoginPage />} />
                                <Route path={AppPageRoutes.UNAUTHORIZED} element={<Unauthorized />} />

                                <Route
                                    element={
                                        <ProtectedRoute isTokenLoaded={isTokenLoaded}>
                                            <LayoutPage />
                                        </ProtectedRoute>
                                    }
                                >
                                    {/* NotFound page makes index automatically when it should be fallback */}
                                    <Route path="*" element={<NotFound />} />

                                    <Route path={AppPageRoutes.DASHBOARD} element={<Dashboard />} />
                                    <Route path={AppPageRoutes.NEWS} element={<News />} />
                                    <Route path={AppPageRoutes.ORDER_NOTIFICATIONS} element={<OrderNotifications />} />

                                    <Route path={AppPageRoutes.BIKE_DASHBOARD} element={<BikeDashboard />} />
                                    <Route path={AppPageRoutes.BIKE_DETAIL} element={<BikeDetail />} />
                                    <Route path={AppPageRoutes.GPS} element={<GPSPage />} />

                                    <Route path={AppPageRoutes.PRODUCTS} element={<ProductsPage />} />
                                    <Route path={AppPageRoutes.THANK_YOU} element={<ThankYouPage />} />
                                    <Route path={AppPageRoutes.SPARE_PARTS} element={<SparePartsPage />} />
                                    <Route
                                        path={AppPageRoutes.MARKETING_MATERIALS}
                                        element={<MarketingMaterialsPage />}
                                    />

                                    <Route path={AppPageRoutes.ORDERS} element={<Orders />} />
                                    <Route path={AppPageRoutes.ORDERS_WITH_ID_PARAM} element={<OrderDetailPage />} />

                                    <Route path={AppPageRoutes.INVOICES} element={<Invoices />} />
                                    <Route path={AppPageRoutes.INVOICE_DETAILS} element={<InvoiceDetails />} />
                                    <Route
                                        path={AppPageRoutes.PAYMENT_PROCESSING}
                                        element={<PaymentProcessingPage />}
                                    />

                                    <Route path={AppPageRoutes.NEW_RETURN_REQUEST} element={<NewReturn />} />
                                    <Route
                                        path={AppPageRoutes.RETURNS_PROCESS_INFORMATION}
                                        element={<ReturnsProcessInfo />}
                                    />
                                    <Route path={AppPageRoutes.THANK_YOU_RETURNS} element={<ThankYouReturnsPage />} />
                                    <Route
                                        path={AppPageRoutes.RETURN_REQUESTS_SELECT_ITEM}
                                        element={<ReturnRequestSelectItemPage />}
                                    />
                                    <Route path={AppPageRoutes.RETURN_REQUESTS} element={<ReturnRequests />} />
                                    <Route
                                        path={AppPageRoutes.RETURN_REQUESTS_ITEM_DETAILS}
                                        element={<ReturnRequestDetails />}
                                    />
                                    {user?.useNewReturnRequest && (
                                        <Route path={AppPageRoutes.SHIP_RETURN_ITEMS} element={<ShipReturns />} />
                                    )}

                                    <Route path={AppPageRoutes.KNOWLEDGE_INFO} element={<KnowledgeInfo />} />
                                    <Route path={AppPageRoutes.CONTACT} element={<Contact />} />
                                </Route>
                            </SentryRoutes>
                        </LocalizationProvider>

                        <ToastContainer />
                        <WarningModal />
                    </RawIntlProvider>
                ) : (
                    <InitialLoading loading={loading} lang={lang} />
                )}
            </>
        );
    }),
);

export default withRoot(App);
